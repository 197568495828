import { Button, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { m } from "framer-motion";
import { GetStaticProps } from "next";
import NextLink from "next/link";

import { getTranslation } from "src/utils/i18n";

import { PageNotFoundIllustration } from "../src/assets";
import { MotionContainer, varBounce } from "../src/components/animate";
import Page from "../src/components/other/Page";
import Layout from "../src/layouts";

export const getStaticProps: GetStaticProps = async function getStaticProps({ locale }) {
	const translations = await getTranslation(locale, ["common"]);
	return {
		props: {
			...translations,
		},
	};
};

const ContentStyle = styled("div")(({ theme }) => ({
	maxWidth: 480,
	margin: "auto",
	minHeight: "100vh",
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	padding: theme.spacing(12, 0),
}));

Page404.getLayout = function getLayout(page: React.ReactElement) {
	return <Layout variant="logoOnly">{page}</Layout>;
};

export default function Page404() {
	return (
		<Page title="404 Page Not Found">
			<Container component={MotionContainer}>
				<ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
					<m.div variants={varBounce().in}>
						<Typography variant="h3" paragraph>
							Sorry, page not found!
						</Typography>
					</m.div>

					<m.div variants={varBounce().in}>
						<Typography sx={{ color: "text.secondary" }}>
							Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check
							your spelling.
						</Typography>
					</m.div>

					<m.div variants={varBounce().in}>
						<PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
					</m.div>

					<NextLink href="/" passHref>
						<Button size="large" variant="contained">
							Go to Home
						</Button>
					</NextLink>
				</ContentStyle>
			</Container>
		</Page>
	);
}
