import { Box, BoxProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { memo } from "react";

import BackgroundIllustration from "./illustration_background";

function PageNotFoundIllustration({ ...other }: BoxProps) {
	const theme = useTheme();

	const PRIMARY_MAIN = theme.palette.primary.main;

	const PRIMARY_DARKER = theme.palette.primary.darker;

	return (
		<Box {...other}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 480 360">
				<BackgroundIllustration />

				{/* SUN */}
				<linearGradient id="SUN" x1="64.297" x2="111.109" y1="95.623" y2="142.436" gradientUnits="userSpaceOnUse">
					<stop stopColor="#FFE16A" />
					<stop offset="1" stopColor="#B78103" />
				</linearGradient>

				<path
					fill="#FFC107"
					d="M111.109 140.247c24.644 0 44.623-19.979 44.623-44.624 0-24.645-19.979-44.623-44.623-44.623-24.645 0-44.624 19.978-44.624 44.623 0 24.645 19.979 44.624 44.624 44.624z"
					opacity="0.15"
				/>

				<path
					fill="url(#SUN)"
					d="M111.109 119.029c12.927 0 23.406-10.479 23.406-23.406 0-12.926-10.479-23.406-23.406-23.406s-23.406 10.48-23.406 23.406c0 12.927 10.48 23.406 23.406 23.406z"
				/>

				{/* CLOUDS */}
				<path
					fill={PRIMARY_MAIN}
					d="M425.621 117.222a8.267 8.267 0 00-9.599-8.157 11.129 11.129 0 00-9.784-5.87h-.403a13.23 13.23 0 00-20.365-14.078 13.23 13.23 0 00-5.316 14.078h-.403a11.153 11.153 0 100 22.293h38.68v-.073a8.279 8.279 0 007.19-8.193zM104.258 199.045a7.093 7.093 0 00-7.093-7.092c-.381.007-.761.04-1.138.097a9.552 9.552 0 00-8.425-5.026h-.343a11.348 11.348 0 10-22.012 0h-.342a9.564 9.564 0 100 19.114h33.177v-.061a7.107 7.107 0 006.176-7.032z"
					opacity="0.24"
				/>
				{/* 0 */}
				<path
					fill={PRIMARY_DARKER}
					d="M244.878 181.46c34.559 0 62.575 28.016 62.575 62.576 0 34.559-28.016 62.575-62.575 62.575-34.56 0-62.576-28.016-62.576-62.575 0-34.56 28.016-62.576 62.576-62.576zm0 23.186c-21.754 0-39.389 17.635-39.389 39.39 0 21.754 17.635 39.389 39.389 39.389s39.389-17.635 39.389-39.389c0-21.755-17.635-39.39-39.389-39.39z"
				/>

				{/* 44 */}
				<path
					fill={PRIMARY_MAIN}
					d="M174.965 264.592c0-4.133-1.492-5.625-5.637-5.625h-11.373v-66.611c0-4.476-1.492-5.637-5.638-5.637h-9.172a9.867 9.867 0 00-7.948 3.974l-55.03 68.274a11.006 11.006 0 00-1.957 6.787v5.968c0 4.145 1.492 5.637 5.625 5.637h54.676v21.707c0 4.133 1.492 5.625 5.625 5.625h8.12c4.146 0 5.638-1.492 5.638-5.625v-21.707h11.434c4.414 0 5.637-1.492 5.637-5.637v-7.13zm-72.42-5.625l35.966-44.415v44.415h-35.966zM411.607 264.592c0-4.133-1.492-5.625-5.638-5.625h-11.421v-66.611c0-4.476-1.492-5.637-5.638-5.637h-9.11a9.869 9.869 0 00-7.949 3.974l-55.03 68.274a10.998 10.998 0 00-1.981 6.787v5.968c0 4.145 1.491 5.637 5.625 5.637h54.688v21.707c0 4.133 1.491 5.625 5.625 5.625h8.12c4.145 0 5.637-1.492 5.637-5.625v-21.707h11.434c4.476 0 5.638-1.492 5.638-5.637v-7.13zm-72.42-5.625l35.966-44.415v44.415h-35.966z"
				/>
			</svg>
		</Box>
	);
}

export default memo(PageNotFoundIllustration);
